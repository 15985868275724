export default {
  props: {
    item: Object,
  },
  data: () => ({
    show_dialog: false,
  }),
  computed: {
    avatar_url() {
      return process.env.VUE_APP_API_ROOT + "/avatar_img/" + this.item.id;
    },
    nome() {
      if (this.item.nome.includes("Vaso")) {
        return this.item.nome.split("Vaso")[0];
      } else {
        return this.item.nome.split("Basket")[0];
      }
    },
    tipo_vaso() {
      if (this.item.unita == "1") {
        return "Vaso Singolo";
      } else {
        return "Cassa da " + this.item.unita;
      }
    },
    numero_prodotti() {
      let carrello = this.$store.getters.get_lista_carrello.find(
        (prod) => prod.art == this.item.id
      );
      if (carrello) {
        return carrello.colori
          .map((col) => col.numero)
          .reduce((tot, num) => tot + num, 0);
      } else {
        return 0;
      }
    },
    image_list() {
      let array = [];
      for (let i = 0; i < this.item.img_list_count; i++) {
        array.push(this.item.img_list_src + i);
      }
      return array;
    },
    prezzo_unitario() {
      return (this.item.prezzo / this.item.unita).toFixed(2);
    },
  },
  methods: {
    open_dettaglio() {
      //this.$refs.dial_dett.open()
    },
    switch_pref() {
      this.item.preferito = !this.item.preferito;
      this.$store.dispatch("switch_pref", this.item);
    },
  },
};
