export default {
  data() {
    return {
      show_all: true,
      search_text: "",
      show_pref_only: false,
    };
  },
  methods: {
    img_categ_src(payload) {
      return process.env.VUE_APP_API_ROOT + "/foto/categoria/" + payload.id + "?t=" + payload.md5;
    },
  },
  computed: {
    filtered() {
      if (this.search_text.length < 3 && !this.show_pref_only) {
        return this.lista;
      } else if (this.show_pref_only) {
        return this.lista
          .map((categoria) => {
            let filt_categ = categoria.sub_categorie
              .map((sub_categoria) => {
                let filt_prod = sub_categoria.prodotti.filter((prodotto) => {
                  return prodotto.preferito;
                });
                return {
                  ...sub_categoria,
                  prodotti: filt_prod,
                };
              })
              .filter((sub_categ) => {
                return sub_categ.prodotti.length > 0;
              });
            return {
              ...categoria,
              sub_categorie: filt_categ,
            };
          })
          .filter((categ) => {
            return categ.sub_categorie.length > 0;
          });
      } else {
        let regex = new RegExp(this.search_text, "i");
        return this.lista
          .map((categoria) => {
            let filt_categ = categoria.sub_categorie
              .map((sub_categoria) => {
                let filt_prod = sub_categoria.prodotti.filter((prodotto) => {
                  return regex.test(prodotto.nome);
                });
                return {
                  ...sub_categoria,
                  prodotti: filt_prod,
                };
              })
              .filter((sub_categ) => {
                return sub_categ.prodotti.length > 0;
              });
            return {
              ...categoria,
              sub_categorie: filt_categ,
            };
          })
          .filter((categ) => {
            return categ.sub_categorie.length > 0;
          });
      }
    },
  },
};
